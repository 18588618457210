module.exports = {
  siteName: 'Arden.nl',
  siteLogo: '/logo/logo_space.png',
  siteUrl: 'https://www.arden.nl',
  siteTitle: 'Arden de Raaij, Front-end Web Developer',
  siteDescription:
    'Front-end Web Developer based in Lisbon, Portugal. I create awesome websites/web-apps which are enjoyable and fun to use.', // Website description used for RSS feeds/meta description tag.
  siteLanguage: 'en_US',
  siteLinks: {
    Github: {
      handle: 'aderaaij'
    },
    Twitter: {
      handle: 'ardennl'
    },
    instagram: {
      handle: 'ardennl'
    },
    email: {
      email: 'a.de.raaij@gmail.com'
    }
  }
};
