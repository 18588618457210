import mc from 'material-colors';
export const colorScheme = {
  dark: mc.blueGrey[900],
  darkLight: mc.blueGrey[800],
  support: mc.pink.a400,
  supportDark: mc.pink[700],
  supportLight: mc.pink.a200,
  text: '#ffffff',
  logo: '#ffffff',
  meta: mc.blueGrey[200]
};

export const fontScheme = {
  text: "'ff-tisa-web-pro', georgia, serif",
  headings: "'proxima-nova', sans-serif"
};
